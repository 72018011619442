"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.map.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên danh mục khuyến nghị"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Tất cả"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.HighlightBookStrategyStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lớp/Nhóm")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "Tất cả"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.classroomId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "classroomId", $$v);
      },
      expression: "query.classroomId"
    }
  }, _vm._l(_vm.classrooms, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm danh mục khuyến nghị")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingSync,
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.handleSync
    }
  }, [_vm._v("Đồng bộ lịch học")])], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đồng bộ lần cuối: ")]), _c("span", [_vm._v(_vm._s(_vm.lastSyncAt ? _vm.$formatDateTime(_vm.lastSyncAt) : "Chưa đồng bộ"))])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Chiến dịch",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng số sách"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.books.length) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lớp/Nhóm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.classrooms ? _c("span", [_vm._v(_vm._s(row.classrooms.map(function (e) {
          return e.name;
        }).join(", ")))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đối tượng cá nhân"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.staffs.length ? _c("span", [_vm._v(_vm._s(row.staffs.length) + " đối tượng")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.startAt && row.endAt ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" Bắt đầu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.startAt, "HH:mm DD/MM/YYYY")))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" Kết thúc: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.endAt, "HH:mm DD/MM/YYYY")))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trọng số",
      prop: "position"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-tag", {
          attrs: {
            type: _vm.HighlightBookStrategyStatusTagColor[row.status],
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s(_vm.HighlightBookStrategyStatusTrans[row.status]) + " ")]), _c("div", [row.status == _vm.HighlightBookStrategyStatus.Reject ? _c("span", [_vm._v(" Lý do từ chối: " + _vm._s(row.rejectReason) + " ")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhân viên tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.pushedStaff ? row.pushedStaff.name : "--") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column"
          }
        }, [_c("el-button", {
          staticStyle: {
            width: "102px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")]), _c("el-button", {
          staticStyle: {
            "margin-left": "0",
            "margin-top": "4px",
            width: "102px"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("RecommendBookStrategyModal", {
    ref: "RecommendBookStrategyModal",
    attrs: {
      isShowApprove: false
    },
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;