"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.map.js");
require("core-js/modules/es.object.to-string.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "recommend-book-modal",
    attrs: {
      title: _vm.status == "update" ? "Cập nhật danh mục khuyến nghị" : "Thêm mới danh mục khuyến nghị",
      visible: _vm.visible,
      width: "800px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên danh mục khuyến nghị",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Môn học",
      prop: "subjectId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: ""
    },
    on: {
      change: _vm.handleChangeSubject
    },
    model: {
      value: _vm.form.subjectId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subjectId", $$v);
      },
      expression: "form.subjectId"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Không chọn",
      value: 0
    }
  }), _vm._l(_vm.subjects, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  })], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Lớp/Nhóm",
      prop: "subjectGroupIds"
    }
  }, [_vm.selectedSubjectGroups.length || _vm.form.subjectId ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetchSubjectGroup,
      expression: "loadingFetchSubjectGroup"
    }],
    ref: "table",
    staticClass: "classroom-table",
    attrs: {
      data: _vm.form.subjectId ? _vm.subjectGroups : _vm.selectedSubjectGroups,
      border: "",
      fit: "",
      stripe: "",
      "header-cell-class-name": _vm.cellClass
    },
    on: {
      select: _vm.handleSelect
    }
  }, [_vm.form.subjectId ? _c("el-table-column", {
    attrs: {
      type: "selection"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Lớp/nhóm",
      prop: "classroom.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Lịch"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.renderWeek(row)) + " ")];
      }
    }], null, false, 733450470)
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.subjectGroupCalendars.length ? _c("span", [_vm._v(" " + _vm._s(row.subjectGroupCalendars[0].startDate) + " " + _vm._s(row.subjectGroupCalendars[0].startTime) + " - " + _vm._s(row.subjectGroupCalendars[0].endDate) + " " + _vm._s(row.subjectGroupCalendars[0].endTime) + " ")]) : _vm._e()];
      }
    }], null, false, 2320102219)
  }), _c("el-table-column", {
    attrs: {
      label: "Học viên"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewStaff(row);
            }
          }
        }, [_vm._v("Xem danh sách")])];
      }
    }], null, false, 2593842361)
  })], 1) : _vm._e(), _c("el-button", {
    staticStyle: {
      "margin-top": "4px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.openSelectSubjectGroup
    }
  }, [_vm._v("Chọn lớp/nhóm")])], 1), _c("el-form-item", {
    attrs: {
      label: "\u0110\u1ED1i t\u01B0\u1EE3ng c\xE1 nh\xE2n (".concat(_vm.form.staffIds.length, ")")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.staffIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffIds", $$v);
      },
      expression: "form.staffIds"
    }
  }, _vm._l(_vm.concatStaff, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.username),
        value: item.id
      }
    });
  }), 1), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleOpenSelectOtherStaff
    }
  }, [_vm._v(" Chọn đối tượng khác ")])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày bắt đầu"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      format: "HH:mm dd/MM/yyyy",
      type: "datetime"
    },
    model: {
      value: _vm.form.startAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startAt", $$v);
      },
      expression: "form.startAt"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày kết thúc"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "HH:mm dd/MM/yyyy",
      clearable: "",
      type: "datetime"
    },
    model: {
      value: _vm.form.endAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endAt", $$v);
      },
      expression: "form.endAt"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Trọng số"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.form.position,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "position", $$v);
      },
      expression: "form.position"
    }
  }, _vm._l(_vm.positions, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Thể loại sách",
      prop: "bookCategoryIds"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      clearable: "",
      "remote-method": _vm.fetchBookCategories
    },
    model: {
      value: _vm.form.bookCategoryIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookCategoryIds", $$v);
      },
      expression: "form.bookCategoryIds"
    }
  }, _vm._l(_vm.bookCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Sách"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleSelectBook
    }
  }, [_vm._v("Chọn sách")]), _c("el-table", {
    attrs: {
      data: _vm.books,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên sách",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("img", {
          staticStyle: {
            "border-radius": "5px",
            width: "40px"
          },
          attrs: {
            src: _vm.$url.image(row.thumbnail),
            alt: ""
          }
        }), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Danh mục",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tác giả",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(row.authors.map(function (e) {
          return e.name;
        }).join(", ")))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chương"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.bookChapters.length ? _c("span", [_vm._v(" " + _vm._s(row.bookChapters.length) + " chương ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var $index = _ref7.$index;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteBook($index);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _vm.recommendBookStrategy.status == _vm.HighlightBookStrategyStatus.Pending ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingUpdate
    },
    on: {
      click: _vm.handleUpdatePending
    }
  }, [_vm._v("Cập nhật")]) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.status == "create" || _vm.recommendBookStrategy.status == _vm.HighlightBookStrategyStatus.Approve ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")]) : _vm._e(), _vm.recommendBookStrategy.status == _vm.HighlightBookStrategyStatus.Pending && _vm.isShowApprove ? _c("div", [_c("div", [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleApprove
    }
  }, [_vm._v("Duyệt")]), _c("el-button", {
    attrs: {
      type: "danger",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleReject
    }
  }, [_vm._v("Từ chối")])], 1)]) : _vm._e()], 1), _c("FilterBookModal", {
    ref: "FilterBookModal",
    on: {
      "submit:ok": _vm.handleSelectBookOk
    }
  }), _c("SelectOtherStaffModal", {
    ref: "SelectOtherStaffModal",
    on: {
      submitOk: _vm.handleSelectOtherStaff
    }
  }), _c("StaffListModal", {
    ref: "StaffListModal"
  }), _c("SelectSubjectGroupModal", {
    ref: "SelectSubjectGroupModal",
    on: {
      submitOk: _vm.handleSelectSubjectGroupOk
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;