"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container dashboard"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-download",
      size: "default"
    },
    on: {
      click: _vm.handleExport
    }
  }, [_vm._v("Xuất báo cáo (Excel)")]), _c("PanelGroup", {
    ref: "PanelGroup"
  }), _c("ImportBookChart", {
    ref: "ImportBookChart"
  }), _c("AccessHistoryChart", {
    ref: "AccessHistoryChart",
    staticStyle: {
      "margin-top": "32px"
    }
  }), _c("ViewBookChart", {
    ref: "ViewBookChart",
    staticStyle: {
      "margin-top": "32px"
    }
  }), _c("TopBookViewTable", {
    ref: "TopBookViewTable",
    staticStyle: {
      "margin-top": "32px"
    }
  }), _c("SummaryBookCategory", {
    ref: "SummaryBookCategory",
    staticStyle: {
      "margin-top": "32px"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;