"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật thông báo" : "Thêm mới thông báo",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề thông báo",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung thông báo",
      prop: "body"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 7
      },
      "show-word-limit": "",
      maxlength: 255
    },
    model: {
      value: _vm.form.body,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "body", $$v);
      },
      expression: "form.body"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đối tượng nhận thông báo"
    }
  }, [_c("el-radio", {
    attrs: {
      disabled: _vm.status == "update",
      label: _vm.NotificationType.General
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Tất cả")]), _c("el-radio", {
    attrs: {
      disabled: _vm.status == "update",
      label: _vm.NotificationType.Range
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("1 lớp")]), _c("el-radio", {
    attrs: {
      disabled: _vm.status == "update",
      label: _vm.NotificationType.MultiClass
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Nhiều lớp")])], 1), _vm.form.type == _vm.NotificationType.Range ? _c("el-form-item", {
    attrs: {
      label: "Lớp/Nhóm"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchClassrooms
    },
    model: {
      value: _vm.form.classroomId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "classroomId", $$v);
      },
      expression: "form.classroomId"
    }
  }, _vm._l(_vm.classrooms, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.form.type == _vm.NotificationType.MultiClass ? _c("el-form-item", {
    attrs: {
      label: "Lớp/Nhóm"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      multiple: "",
      "remote-method": _vm.fetchClassrooms
    },
    model: {
      value: _vm.form.classroomIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "classroomIds", $$v);
      },
      expression: "form.classroomIds"
    }
  }, _vm._l(_vm.classrooms, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Loại thông báo"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.NotificationContentType.Normal
    },
    model: {
      value: _vm.form.contentType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contentType", $$v);
      },
      expression: "form.contentType"
    }
  }, [_vm._v("Thông thường (Mở app)")]), _c("el-radio", {
    attrs: {
      label: _vm.NotificationContentType.Banner
    },
    model: {
      value: _vm.form.contentType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contentType", $$v);
      },
      expression: "form.contentType"
    }
  }, [_vm._v("Banner")]), _c("el-radio", {
    attrs: {
      label: _vm.NotificationContentType.Book
    },
    model: {
      value: _vm.form.contentType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contentType", $$v);
      },
      expression: "form.contentType"
    }
  }, [_vm._v("Sách")])], 1), _vm.form.contentType == _vm.NotificationContentType.Banner ? _c("el-form-item", {
    attrs: {
      label: "Banner",
      prop: "bannerId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchBanners
    },
    model: {
      value: _vm.form.bannerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bannerId", $$v);
      },
      expression: "form.bannerId"
    }
  }, _vm._l(_vm.banners, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.form.contentType == _vm.NotificationContentType.Book ? _c("el-form-item", {
    attrs: {
      label: "Sách",
      prop: "bookId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchBooks
    },
    model: {
      value: _vm.form.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookId", $$v);
      },
      expression: "form.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;