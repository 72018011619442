"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm._m(0);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-professional"
  }, [_c("p", [_vm._v("Booking as a customer? ")]), _c("div", {
    staticClass: "content"
  }, [_c("span", [_vm._v("This is the partner area, please go to")]), _c("a", {
    staticClass: "link",
    attrs: {
      href: "https://",
      target: "_blank"
    }
  }, [_vm._v(" booker log in ")])])]);
}];
render._withStripped = true;