"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật chương" : "Thêm mới chương",
      "append-to-body": "",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForm,
      expression: "loadingForm"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Pdf file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.pdfChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfChooseType", $$v);
      },
      expression: "form.pdfChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.pdfChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfChooseType", $$v);
      },
      expression: "form.pdfChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.pdfChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      action: "".concat(_vm.$baseUrl, "/v1/admin/book/upload"),
      limit: 1,
      "on-success": _vm.handleUploadPdfOk,
      "before-upload": _vm.beforeUploadPdf,
      "on-remove": _vm.handleRemovePdf,
      headers: {
        token: _vm.token
      },
      "file-list": _vm.pdfFileList
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _c("el-input", {
    model: {
      value: _vm.form.pdfLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.pdfLink"
    }
  })] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Audio file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.audioChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioChooseType", $$v);
      },
      expression: "form.audioChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.audioChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioChooseType", $$v);
      },
      expression: "form.audioChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.audioChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": _vm.handleUploadAudioOk,
      "file-list": _vm.audioFileList,
      "on-remove": _vm.handleRemoveAudio
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _c("el-input", {
    model: {
      value: _vm.form.audioLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.audioLink"
    }
  })] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Video file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.videoChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoChooseType", $$v);
      },
      expression: "form.videoChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.videoChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoChooseType", $$v);
      },
      expression: "form.videoChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.videoChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": _vm.handleUploadVideoOk,
      "file-list": _vm.videoFileList,
      "on-remove": _vm.handleRemoveVideo
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _c("el-input", {
    model: {
      value: _vm.form.videoLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.videoLink"
    }
  })] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Khảo sát"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.BookChapterSurveyDataType.Url
    },
    model: {
      value: _vm.form.surveyDataType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyDataType", $$v);
      },
      expression: "form.surveyDataType"
    }
  }, [_vm._v(" Dạng URL ")]), _c("el-radio", {
    attrs: {
      label: _vm.BookChapterSurveyDataType.Html
    },
    model: {
      value: _vm.form.surveyDataType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyDataType", $$v);
      },
      expression: "form.surveyDataType"
    }
  }, [_vm._v(" Dạng HTML ")]), _vm.form.surveyDataType == _vm.BookChapterSurveyDataType.Url ? _c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.surveyContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyContent", $$v);
      },
      expression: "form.surveyContent"
    }
  }) : _vm._e(), _vm.form.surveyDataType == _vm.BookChapterSurveyDataType.Html && _vm.visible ? _c("Tinymce", {
    ref: "editor",
    on: {
      onInit: _vm.handleEditorInit
    },
    model: {
      value: _vm.form.surveyContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyContent", $$v);
      },
      expression: "form.surveyContent"
    }
  }) : _vm._e()], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;