"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.syncHistoryApi = void 0;
var _request = require("@/utils/request");
var syncHistoryApi = exports.syncHistoryApi = {
  last: function last(params) {
    return (0, _request.request)({
      url: '/v1/admin/syncHistory/last',
      params: params
    });
  }
};