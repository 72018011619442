"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới người dùng" : "Cập nhật người dùng",
      visible: _vm.visible,
      width: "600px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SyncId",
      prop: "syncId"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.syncId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "syncId", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.syncId"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Username",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _vm.status == "create" ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mật khẩu",
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      type: _vm.showPass ? "text" : "password"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c("div", {
    staticClass: "show-pwd"
  }, [!_vm.showPass ? _c("font-awesome-icon", {
    attrs: {
      icon: "eye"
    },
    on: {
      click: function click($event) {
        _vm.showPass = true;
      }
    }
  }) : _c("font-awesome-icon", {
    attrs: {
      icon: "eye-slash"
    },
    on: {
      click: function click($event) {
        _vm.showPass = false;
      }
    }
  })], 1)], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "show-word-limit": "",
      maxlength: 40,
      size: "medium"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SDT",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      value: _vm.form.phone
    },
    on: {
      input: _vm.handleChangePhone
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại người dùng",
      prop: "roleId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }, _vm._l(_vm.roles, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Lớp/Nhóm"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: ""
    },
    model: {
      value: _vm.form.classroomIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "classroomIds", $$v);
      },
      expression: "form.classroomIds"
    }
  }, _vm._l(_vm.classrooms, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Avatar",
      prop: "avatar"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 100,
      pathImage: _vm.form.avatar,
      uploadUrl: "/v1/admin/staff/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.form, "avatar", path);
      }
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.status != "create" ? _c("div", [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "danger",
      size: "default",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium",
      type: "warning",
      loading: _vm.loadingReset
    },
    on: {
      click: _vm.handleResetPwd
    }
  }, [_vm._v("Reset mật khẩu")])], 1) : _vm._e(), _c("div", {}, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;