"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Sắp xếp chương",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.bookChapters,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thứ tự",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          model: {
            value: row.position,
            callback: function callback($$v) {
              _vm.$set(row, "position", $$v);
            },
            expression: "row.position"
          }
        })];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Cập nhật")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;