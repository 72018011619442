"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/permission");
var _default = exports.default = {
  name: "Page404",
  computed: {
    message: function message() {
      return "The webmaster said that you can not enter this page...";
    },
    homeRoute: function homeRoute() {
      return _permission.firstRoute[this.$store.state.user.info.type];
    }
  }
};