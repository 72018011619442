"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.filter.js");
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _path = _interopRequireDefault(require("path"));
var _validate = require("@/utils/validate");
var _Item = _interopRequireDefault(require("./Item"));
var _Link = _interopRequireDefault(require("./Link"));
var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));
var _settings = require("../../../settings");
var _vuex = require("vuex");
var _default = exports.default = {
  name: "SidebarItem",
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    userInfo: function userInfo(state) {
      return state.user.info;
    }
  })),
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    handlePermissionRoute: function handlePermissionRoute() {
      if (this.item.alwayShow) {
        return true;
      } else if (_settings.checkPermission) {
        if (this.item.hidden || !this.item.access) {
          return false;
        }
      }
      if (this.item.hidden) {
        return false;
      }
      return true;

      // if (checkPermission) {
      //   if (
      //     !this.item.hidden &&
      //     this.item.meta &&
      //     this.item.meta.roles &&
      //     this.item.meta.roles.includes(this.userInfo.type)
      //   ) {
      //     return true;
      //   }
      //   if (this.item.hidden || !this.item.access) {
      //     return false;
      //   }
      // }
      // if (this.item.hidden) {
      //   return false;
      // }
      // return true;
    },
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: "",
          noShowingChildren: true
        });
        return true;
      }
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }
      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }
      var resPath = _path.default.resolve(this.basePath, routePath);
      return _path.default.resolve(this.basePath, routePath);
    }
  }
};