"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookType = exports.BookSyncFromTrans = exports.BookSyncFrom = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BookType;
(function (BookType) {
  BookType["Physical"] = "PHYSICAL";
  BookType["EBook"] = "E-BOOK"; //sách điện tử
})(BookType || (exports.BookType = BookType = {}));
var BookSyncFrom;
(function (BookSyncFrom) {
  BookSyncFrom["Visedu"] = "VISEDU";
  BookSyncFrom["Batch"] = "BATCH";
  BookSyncFrom["Opac"] = "OPAC";
})(BookSyncFrom || (exports.BookSyncFrom = BookSyncFrom = {}));
var BookSyncFromTrans = exports.BookSyncFromTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookSyncFrom.Visedu, 'Từ thư viện số Dspace'), BookSyncFrom.Batch, 'Nhập theo lô'), BookSyncFrom.Opac, 'Từ Sierra (Sách giấy)');