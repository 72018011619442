"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.number.to-fixed.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "chart-container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thể loại",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Lượt xem",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.viewCount)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đánh giá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("img", {
          attrs: {
            src: _vm.starIcon,
            width: "20"
          }
        }), _c("span", {
          staticStyle: {
            "font-weight": "bold",
            "vertical-align": "middle"
          }
        }, [_vm._v(_vm._s(row.totalRate == 0 ? "Mới" : (row.totalStar / row.totalRate).toFixed(1)))]), row.totalRate ? _c("div", [_c("span", [_vm._v(_vm._s(row.totalRate) + " đánh giá")])]) : _vm._e()];
      }
    }])
  })], 1), _c("label", {
    staticClass: "chart-title",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Top 10 nội dung số được xem nhiều nhất ")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;