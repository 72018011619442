"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.historyBookApi = void 0;
var _request = require("@/utils/request");
var historyBookApi = exports.historyBookApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/historyBook/admin',
      params: params
    });
  }
};