"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffTypeTrans = exports.StaffType = exports.CreateFrom = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var StaffType;
(function (StaffType) {
  StaffType["Customer"] = "CUSTOMER";
  StaffType["Teacher"] = "TEACHER";
  StaffType["Staff"] = "STAFF";
  StaffType["Librarian"] = "LIBRARIAN";
  StaffType["Technician"] = "TECHNICIAN";
  StaffType["TeacherAv"] = "TEACHER_AV"; //Giảng viên cấp cao
})(StaffType || (exports.StaffType = StaffType = {}));
var StaffTypeTrans = exports.StaffTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, StaffType.Customer, 'Học viên'), StaffType.Teacher, 'Giảng viên'), StaffType.Staff, 'Quản trị viên'), StaffType.Librarian, 'Thủ thư'), StaffType.Technician, 'Kỹ thuật viên'), StaffType.TeacherAv, 'Giảng viên cấp cao');
var CreateFrom;
(function (CreateFrom) {
  CreateFrom["Self"] = "SELF";
  CreateFrom["Other"] = "OTHER"; //Tạo ra từ bên thứ 3
})(CreateFrom || (exports.CreateFrom = CreateFrom = {}));