"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }), _c("el-table", {
    attrs: {
      data: _vm.tableData,
      border: "",
      stripe: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(_vm._s(_vm.ContentDefineTypeTrans[row.type]))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Xem chi tiết")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1), _c("ContentDefineModal", {
    ref: "ContentDefineModal",
    on: {
      submitOk: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;