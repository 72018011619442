"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.token && _vm.url ? _c("iframe", {
    staticStyle: {
      width: "100%",
      height: "calc(100vh - 90px)"
    },
    attrs: {
      src: _vm.src,
      frameborder: "0"
    }
  }) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;