"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8,
      xs: 24
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "icon-box"
  }, [_c("img", {
    attrs: {
      src: _vm.bookIcon,
      width: "250",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "card-panel-content"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng nội dung số")]), _c("br"), _c("span", {
    staticClass: "description"
  }, [_c("countTo", {
    attrs: {
      startVal: 0,
      endVal: _vm.data.totalBook,
      duration: 3000
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.data.totalBook)) + " ")])], 1)])])]), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8,
      xs: 24
    }
  }, [_c("div", {
    staticClass: "card-panel profit"
  }, [_c("div", {
    staticClass: "icon-box"
  }, [_c("img", {
    attrs: {
      src: _vm.eyeIcon,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "card-panel-content"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng lượt xem")]), _c("br"), _c("span", {
    staticClass: "description"
  }, [_c("countTo", {
    attrs: {
      startVal: 0,
      endVal: _vm.data.totalView,
      duration: 3000
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.data.totalView)) + " ")])], 1)])])]), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8,
      xs: 24
    }
  }, [_c("div", {
    staticClass: "card-panel profit"
  }, [_c("div", {
    staticClass: "icon-box"
  }, [_c("img", {
    attrs: {
      src: _vm.studentIcon,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "card-panel-content"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng học viên")]), _c("br"), _c("span", {
    staticClass: "description"
  }, [_c("countTo", {
    attrs: {
      startVal: 0,
      endVal: _vm.data.totalStaff,
      duration: 3000
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.data.totalStaff)) + " ")])], 1)])])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;