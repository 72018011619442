"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentDefineTypeTrans = exports.ContentDefineType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ContentDefineType;
(function (ContentDefineType) {
  ContentDefineType["TermsOfUse"] = "TERMS_OF_USE";
  ContentDefineType["Faq"] = "FAQ";
  ContentDefineType["TutorialUsage"] = "TUTORIAL_USAGE";
  ContentDefineType["ContactInformation"] = "CONTACT_INFORMATION";
})(ContentDefineType || (exports.ContentDefineType = ContentDefineType = {}));
var ContentDefineTypeTrans = exports.ContentDefineTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ContentDefineType.Faq, 'Câu hỏi thường gặp'), ContentDefineType.TermsOfUse, 'Điều khoản & chính sách'), ContentDefineType.TutorialUsage, 'Hướng dẫn cài đặt App'), ContentDefineType.ContactInformation, 'Thông tin liên hệ');