"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileApi = void 0;
var _request = require("@/utils/request");
var fileApi = exports.fileApi = {
  findFolder: function findFolder(params) {
    return (0, _request.request)({
      url: '/v1/admin/file/folder',
      params: params
    });
  },
  findFile: function findFile(params) {
    return (0, _request.request)({
      url: '/v1/admin/file/folder/file',
      params: params
    });
  },
  findLog: function findLog(params) {
    return (0, _request.request)({
      url: '/v1/admin/file/log',
      params: params
    });
  },
  findLogKiosk: function findLogKiosk(params) {
    return (0, _request.request)({
      url: '/v1/admin/file/log/kiosk',
      params: params
    });
  }
};