"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.syncApi = void 0;
var _request = require("@/utils/request");
var timeout = 1000 * 60 * 10;
var syncApi = exports.syncApi = {
  syncGroup: function syncGroup(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/group',
      params: params,
      timeout: timeout
    });
  },
  syncBook: function syncBook(data) {
    return (0, _request.request)({
      url: '/v1/admin/sync/book',
      data: data,
      method: 'post'
    });
  },
  fetchCategory: function fetchCategory(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/fetch/category',
      params: params
    });
  },
  fetchBook: function fetchBook(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/fetch/book',
      params: params,
      timeout: timeout
    });
  },
  syncBookV2: function syncBookV2(data) {
    return (0, _request.request)({
      url: '/v1/admin/sync/book/v2',
      data: data,
      method: 'post',
      timeout: timeout
    });
  },
  syncObject: function syncObject(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/object',
      params: params,
      timeout: timeout
    });
  },
  syncClassroom: function syncClassroom(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/classroom',
      method: 'post'
    });
  },
  syncSubject: function syncSubject(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/subject',
      method: 'post'
    });
  },
  syncOpacBook: function syncOpacBook(params) {
    return (0, _request.request)({
      url: '/v1/admin/sync/book/opac',
      method: 'post',
      timeout: timeout
    });
  }
};