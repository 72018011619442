"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("input", {
    ref: "excel-upload-input",
    staticClass: "excel-upload-input",
    attrs: {
      type: "file",
      accept: ".xlsx, .xls"
    },
    on: {
      change: _vm.handleClick
    }
  }), _c("div", {
    staticClass: "drop",
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragover,
      dragenter: _vm.handleDragover
    }
  }, [_vm._v(" Drop excel file here or "), _c("el-button", {
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      loading: _vm.loading,
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.handleUpload
    }
  }, [_vm._v(" Browse ")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;