"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findOne = findOne;
exports.staffApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var staffApi = exports.staffApi = {
  reset: reset,
  findOne: findOne,
  findAll: findAll,
  create: create,
  update: update,
  remove: remove,
  block: function block(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/block"),
      method: "patch"
    });
  },
  unblock: function unblock(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/unblock"),
      method: "patch"
    });
  },
  activeBatch: function activeBatch(data) {
    return (0, _request.default)({
      url: "/v1/admin/staff/active/batch",
      method: "patch",
      data: data
    });
  },
  delete: function _delete(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId),
      method: "delete"
    });
  }
};
function reset(id, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(id, "/password/reset"),
    method: "patch",
    data: data
  });
}
function findOne(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId),
    method: "get"
  });
}
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "post",
    data: data
  });
}
function update(userId, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId),
    method: "patch",
    data: data
  });
}
function remove(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/delete"),
    method: "delete"
  });
}