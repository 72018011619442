"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _c("sidebar", {
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()], 1), _c("app-main"), _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;