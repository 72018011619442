"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _c("div", {
    staticClass: "right-menu"
  }, [_c("span", {
    staticClass: "version"
  }, [_vm._v(" v" + _vm._s(_vm.version) + " ")]), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.avatar ? _vm.$url.image(_vm.avatar) : require("@/assets/images/avatar_default.svg")
    }
  }), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/profile/index"
    }
  }, [_c("el-dropdown-item", [_vm._v("Profile")])], 1), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.logout
    }
  }, [_vm._v("Log Out")])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;