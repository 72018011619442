"use strict";

var _interopRequireWildcard = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Volumes/Untitled/project/298/214admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var VueGoogleMaps = _interopRequireWildcard(require("gmap-vue"));
_vue.default.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_KEY_GOOGLE_MAP,
    libraries: "places",
    region: "VI",
    language: "vi"
  }
});