"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loadingImport
    },
    on: {
      click: _vm.importRoutesPath
    }
  }, [_vm._v("Import")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.$refs["add-role"].handleAdd();
      }
    }
  }, [_vm._v(" Thêm mới ")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-top": "30px"
    },
    attrs: {
      data: _vm.rolesList
    },
    on: {
      "row-click": _vm.handleEdit
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "Quyền",
      width: "220"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.name))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "Mô tả"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.description))];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: "Cập nhật",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dialogLoading,
      expression: "dialogLoading"
    }],
    attrs: {
      model: _vm.role,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Tên quyền"
    },
    model: {
      value: _vm.role.name,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "name", $$v);
      },
      expression: "role.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Type",
      prop: "type"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c("span", [_vm._v("Type")]), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Type này sẽ đồng bộ với account.portalRole khi đồng bộ đối tượng",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-info",
          staticStyle: {
            display: "inline-lock",
            "margin-left": "4px"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.role.type,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "type", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "role.type"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả"
    }
  }, [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      type: "textarea",
      placeholder: "Mô tả"
    },
    model: {
      value: _vm.role.description,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "description", $$v);
      },
      expression: "role.description"
    }
  })], 1), _c("div", [_c("el-checkbox", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.role.isAllowAccessAdmin,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "isAllowAccessAdmin", $$v);
      },
      expression: "role.isAllowAccessAdmin"
    }
  }, [_vm._v("Cho phép đăng nhập vào portal")])], 1), _vm.role.isAllowAccessAdmin ? _c("el-form-item", {
    attrs: {
      label: "Menus"
    }
  }, [_vm.checkStrictly ? _c("el-tree", {
    ref: "tree",
    staticClass: "permission-tree",
    attrs: {
      "default-expand-all": true,
      data: _vm.routes,
      props: _vm.defaultProps,
      "default-checked-keys": _vm.defaultCheck,
      "show-checkbox": "",
      "node-key": "path"
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingUpdate
    },
    on: {
      click: _vm.confirmRole
    }
  }, [_vm._v("Lưu")])], 1)], 1), _c("add-role", {
    ref: "add-role",
    on: {
      "add:success": _vm.getRoles
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;