"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deviceApi = void 0;
var _request = require("@/utils/request");
var deviceApi = exports.deviceApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/device',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/device',
      data: data,
      method: 'post'
    });
  },
  byPass: function byPass(id) {
    return (0, _request.request)({
      url: "/v1/admin/device/".concat(id, "/by-pass"),
      method: 'patch'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/device/".concat(id),
      method: 'patch',
      data: data
    });
  },
  valid: function valid(id) {
    return (0, _request.request)({
      url: "/v1/admin/device/".concat(id, "/valid"),
      method: 'patch'
    });
  },
  inValid: function inValid(id) {
    return (0, _request.request)({
      url: "/v1/admin/device/".concat(id, "/invalid"),
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/device/".concat(id),
      method: 'delete'
    });
  }
};