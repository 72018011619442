"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importBookBatchApi = void 0;
var _request = require("@/utils/request");
var importBookBatchApi = exports.importBookBatchApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/importBookBatch',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/importBookBatch/".concat(id)
    });
  }
};