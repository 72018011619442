"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: "Thêm mới",
      visible: _vm.dialogVisible,
      width: "600px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.role,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên quyền",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.role.name,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "name", $$v);
      },
      expression: "role.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Type",
      prop: "type"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c("span", [_vm._v("Type")]), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Type này sẽ đồng bộ với account.portalRole khi đồng bộ đối tượng",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-info",
          staticStyle: {
            display: "inline-lock",
            "margin-left": "4px"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.role.type,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "type", $$v);
      },
      expression: "role.type"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả"
    }
  }, [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      type: "textarea"
    },
    model: {
      value: _vm.role.description,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "description", $$v);
      },
      expression: "role.description"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.buttonLoading
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;